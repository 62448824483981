<template>
  <div class="success">
    <div class="payimg">
      <img src="../../assets/fail.png" alt="" />
      <div class="paysuccess">支付失败,请重新扫码进入支付</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
};
</script>

<style lang="scss" scoped>
.success {
  .payimg {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    width: 100%;
    height: 100px;
    img {
      width: 100px;
      height: 100px;
    }

    .paysuccess {
      margin: 20px 0;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>
